import { Injectable } from '@angular/core';
import { AbstractRouteManagement } from 'bp-angular-library';

@Injectable({
  providedIn: 'root'
})
export class RouteManagementService extends AbstractRouteManagement {
  public navigateToCasinoView(view: 'all-games' | 'preview' | 'play-real' | 'play-demo', gameId?: number): void {
    console.log('RouteManagementService class, navigateToCasinoView: ', view, gameId);
    console.log('RouteManagementService class, navigateToCasinoView, this.router: ', this.router);
    console.log('RouteManagementService class, navigateToCasinoView, this.activatedRoute: ', this.activatedRoute.snapshot.queryParams);
  }
}
