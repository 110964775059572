import { Routes } from '@angular/router';
import { IApiPayload, IApiRequestParams } from 'bp-framework/dist/api/api.interface';
import { IJackpotDetailsForAdmin } from 'bp-framework/dist/casino/casino.interface';

import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { ITransactionGroup } from 'bp-framework/dist/transactions/transaction-groups.interface';

import { ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

export abstract class UserAbstractService {
  abstract loginWithUsernameAndPassword(username: string, password: string): Promise<IApiPayload<Partial<IUserDetails>> | null>;
  abstract refreshToken(): Promise<Partial<IUserDetails> | null>;
  abstract getUserProfile(): Promise<IApiPayload<Partial<IUserDetails>> | null>;
  abstract patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null>;
  abstract updateUserWithProfileData(): Promise<Partial<IUserDetails> | null>;
  abstract watchForLanguageChange(): void;
}

export abstract class ContentAbstractService {
  abstract buildAllRoutes(): Routes;
  abstract buildSidemenuRoutes(): Partial<INavGroup>[];
}

export abstract class AdminAbstractService {
  abstract transactions: Partial<ITransactionDetails>[];
  abstract adminRegisterPlayer(username: string, password: string, language: string, phone: string): Promise<IApiPayload<any>>;
  abstract adminGetPlayersList(params: IApiRequestParams): Promise<IApiPayload<Partial<IUserDetails>[]>>;
  abstract adminGetPlayerById(playerId: string): Promise<IApiPayload<Partial<IUserDetails> | null>>;
  abstract adminMakePlayerDeposit(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>>;
  abstract adminMakePlayerWithdrawal(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>>;
  abstract adminBlockPlayer(playerId: string): Promise<IApiPayload<unknown>>;
  abstract adminUnblockPlayer(playerId: string): Promise<IApiPayload<unknown>>;
  abstract adminAwardJackpot(playerId: string, jackpotId: number): Promise<IApiPayload<unknown>>;
  abstract adminGetJackpotsList(params: IApiRequestParams): Promise<IApiPayload<IJackpotDetailsForAdmin[]>>;
  abstract adminGetTransactions(startDateIso: string, endDateIso: string, limit: number, offset: number, transactionTypes: TransactionType[], transactionGroupIds: string[], userIds?: string[]): Promise<IApiPayload<Partial<ITransactionDetails>[]>>;
}

export abstract class CasinoAbstractService {}

export abstract class GlobalAbstractService {
  abstract getTransactionGroups(): Promise<IApiPayload<ITransactionGroup[]> | null>;
}
