import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';

import { IEnvConfigBetPlatform } from 'bp-angular-library';

export const BASE_URLS = {
  default: 'https://bp.app.tasp13.com'
};

export const config: IEnvConfigBetPlatform = {
  general: {
    production: true,
    environmentName: '',
    projectName: 'bp-back-office',
    brand: 'generic'
  },
  storage: {
    prefix: PROJECT_PREFIX.backOfficeApp
  },
  api: {
    provider: 'betplatform',
    baseUrls: {
      default: BASE_URLS.default
    },
    httpInterceptors: {
      attachAuthTokenToTheseUrls: [BASE_URLS.default],
      doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS.default}/auth/login`],
      skip401HandlingForTheseUrls: [`${BASE_URLS.default}/auth/refresh`]
    }
  },
  features: {
    frontendMode: 'default',
    storeCredentials: false
  },
  authorization: {
    role: ''
  }
};

export const environment: IEnvConfigBetPlatform = config;
