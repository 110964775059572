import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { AbstractGlobalInterceptor, GlobalAuthenticationService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/services/env-abstracts';

import { from, Observable } from 'rxjs';

@Injectable()
export class GlobalInterceptor1x2Team extends AbstractGlobalInterceptor {
  private authService: GlobalAuthenticationService = inject(GlobalAuthenticationService);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);

  constructor() {
    super();
  }

  override baseGetAuthToken(): string | null {
    return this.authService.rawToken$.value;
  }

  override baseTryRefreshingToken(): Observable<Partial<IUserDetails> | null> {
    return from(this.userAbstractService.refreshToken());
  }

  override baseHandleAfterFailedTokenRefresh(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === 401 && this.authService.isLoggedIn) {
      this.authService.logout();
    }
  }

  override baseAddTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `token ${this.baseGetAuthToken()}`)
    });
  }
}
