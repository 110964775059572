import { GlobalAuthenticationService, I18nService, IProjectConfigAgnostic, PROJECT_CONFIG_TOKEN, ProjectConfig, StorageService } from 'bp-angular-library';

import { Router, Routes } from '@angular/router';

import { ContentAbstractService, UserAbstractService } from '../services/env-abstracts';
import { inject } from '@angular/core';
import { SUPPORTED_LANGUAGE_OPTIONS } from 'src/app/shared/models/i18n/i18n.const';
import { RouteManagementService } from '../services/route-management/route-management.service';

export function appInitFactory(): () => Promise<boolean> {
  const projectConfig = inject<ProjectConfig<IProjectConfigAgnostic>>(PROJECT_CONFIG_TOKEN);
  const router: Router = inject(Router);
  const storageService: StorageService = inject(StorageService);
  const authService: GlobalAuthenticationService = inject(GlobalAuthenticationService);
  const i18nService: I18nService = inject(I18nService);
  const contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  const userAbstractService = inject(UserAbstractService);
  const routeManagementService = inject(RouteManagementService);

  return () =>
    new Promise(async (resolve, reject) => {
      try {
        await storageService.initializeStorageService(projectConfig?.storage?.prefix);
        await authService.handleAppInitialization();
        await i18nService.initializeLanguage(SUPPORTED_LANGUAGE_OPTIONS);
        userAbstractService.watchForLanguageChange();
        const appRoutes: Routes = contentAbstractService.buildAllRoutes();
        router.resetConfig(appRoutes);

        routeManagementService.initializeAbstractRouteManagement();
        resolve(true);
      } catch (error) {
        console.error('Failed to initialize the app:', error);
        reject(error);
      }
    });
}
