import { AppComponent } from './app/app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';

import { appInitFactory } from './app/core/initializer/initializers';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, Injector, isDevMode } from '@angular/core';
import { Casino1x2TeamService } from './@env-specific/providers/1x2team/services/casino-1x2team.service';
import { CasinoBetplatformService } from './@env-specific/providers/betplatform/services/casino-betplatform.service';
import { Admin1x2TeamService } from './@env-specific/providers/1x2team/services/admin-1x2team.service';
import { AdminBetplatformService } from './@env-specific/providers/betplatform/services/admin-betplatform.service';
import { User1x2TeamService } from './@env-specific/providers/1x2team/services/user-1x2team.service';
import { UserBetplatformService } from './@env-specific/providers/betplatform/services/user-betplatform.service';
import { Content1x2TeamService } from './@env-specific/providers/1x2team/services/content-1x2team.service';
import { ContentBetplatformService } from './@env-specific/providers/betplatform/services/content-betplatform.service';
import { ContentAbstractService, UserAbstractService, AdminAbstractService, CasinoAbstractService, GlobalAbstractService } from './app/core/services/env-abstracts';
import { environment } from 'src/environments/environment';
import { setupServiceLocator, GlobalErrorHandlerService, IProjectConfigAgnostic, PROJECT_CONFIG_TOKEN } from 'bp-angular-library';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { GlobalBetplatformService } from './@env-specific/providers/betplatform/services/global-betplatform.service';
import { Global1x2TeamService } from './@env-specific/providers/1x2team/services/global-1x2team.service';
import { GlobalInterceptorBetPlatform } from './@env-specific/providers/betplatform/interceptors/global.betplatform.interceptor';
import { GlobalInterceptor1x2Team } from './@env-specific/providers/1x2team/interceptors/global.1x2team.interceptor';
import { provideServiceWorker } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      IonicModule.forRoot({
        mode: 'md',
        rippleEffect: false,
        swipeBackEnabled: false
      }),
      IonicStorageModule.forRoot({
        name: '__appdb',
        driverOrder: [Drivers.LocalStorage]
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => () => setupServiceLocator(injector),
      deps: [Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new GlobalInterceptorBetPlatform();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new GlobalInterceptor1x2Team();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN],
      multi: true
    },
    { provide: PROJECT_CONFIG_TOKEN, useValue: environment },
    {
      provide: ContentAbstractService,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new ContentBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Content1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN]
    },
    {
      provide: UserAbstractService,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new UserBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new User1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN]
    },
    {
      provide: AdminAbstractService,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new AdminBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Admin1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN]
    },
    {
      provide: CasinoAbstractService,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new CasinoBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Casino1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN]
    },
    {
      provide: GlobalAbstractService,
      useFactory: (projectConfig: IProjectConfigAgnostic) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new GlobalBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Global1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_CONFIG_TOKEN]
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
}).catch(err => console.error(err));
