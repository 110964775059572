<lib-network-status-bar (handleNetworkStatusChange)="handleNetworkStatusChange($event)" [class.is-visible]="showNetworkStatus"></lib-network-status-bar>
<ion-split-pane contentId="main" when="(min-width: 1280px)" [class.has-network-status-msg]="showNetworkStatus">
  <ion-menu menuId="primary-sidemenu" contentId="main" swipeGesture="false">
    <app-sidemenu></app-sidemenu>
  </ion-menu>
  <!-- Ion Page -->
  <div class="ion-page" id="main" class="router-wrapper">
    <ion-router-outlet></ion-router-outlet>
  </div>
</ion-split-pane>
