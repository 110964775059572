import { Injectable } from '@angular/core';
import { AdminAbstractService } from 'src/app/core/services/env-abstracts';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ITransactionDetails } from 'bp-framework/dist/transactions/transactions.interface';
import { IApiPayload, IApiRequestParams } from 'bp-framework/dist/api/api.interface';
import { IJackpotDetailsForAdmin } from 'bp-framework/dist/casino/casino.interface';

@Injectable({
  providedIn: 'root'
})
export class Admin1x2TeamService extends AdminAbstractService {
  public transactions: ITransactionDetails[] = [];
  adminRegisterPlayer(username: string, password: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  adminGetPlayersList(params: IApiRequestParams): Promise<IApiPayload<Partial<IUserDetails>[]>> {
    throw new Error('Method not implemented.');
  }
  adminGetPlayerById(playerId: string): Promise<IApiPayload<Partial<IUserDetails> | null>> {
    throw new Error('Method not implemented.');
  }
  adminMakePlayerDeposit(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>> {
    throw new Error('Method not implemented.');
  }
  adminMakePlayerWithdrawal(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>> {
    throw new Error('Method not implemented.');
  }
  getPlayerTransactions(playerId: string, category: any, startDate: Date, endDate: Date): Promise<IApiPayload<Partial<ITransactionDetails>[]>> {
    throw new Error('Method not implemented.');
  }
  adminBlockPlayer(playerId: string): Promise<IApiPayload<unknown>> {
    throw new Error('Method not implemented.');
  }
  adminUnblockPlayer(playerId: string): Promise<IApiPayload<unknown>> {
    throw new Error('Method not implemented.');
  }
  adminAwardJackpot(playerId: string, jackpotId: number): Promise<IApiPayload<unknown>> {
    throw new Error('Method not implemented.');
  }
  adminGetJackpotsList(): Promise<IApiPayload<IJackpotDetailsForAdmin[]>> {
    throw new Error('Method not implemented.');
  }

  adminGetTransactions(): Promise<IApiPayload<Partial<ITransactionDetails>[]>> {
    throw new Error('Method not implemented.');
  }
}
