import { Injectable } from '@angular/core';

import { ContentAbstractService } from 'src/app/core/services/env-abstracts';
import { Routes } from '@angular/router';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { ALL_ROUTES_PROD, SIDEMENU_NAV_GROUPS_PROD } from 'src/@env-specific/routes/routes.generic.prod';

@Injectable({
  providedIn: 'root'
})
export class ContentBetplatformService extends ContentAbstractService {
  buildAllRoutes(): Routes {
    return ALL_ROUTES_PROD;
  }
  buildSidemenuRoutes(): Partial<INavGroup>[] {
    return SIDEMENU_NAV_GROUPS_PROD;
  }
}
