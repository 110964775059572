import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';

import { INavGroup } from 'bp-framework/dist/common/common.interface';

import { ContentAbstractService } from 'src/app/core/services/env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class Content1x2TeamService extends ContentAbstractService {
  buildAllRoutes(): Routes {
    throw new Error('Method not implemented.');
  }
  buildSidemenuRoutes(): Partial<INavGroup>[] {
    throw new Error('Method not implemented.');
  }
}
