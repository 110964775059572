import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayConditionType, INavGroup, ISidemenuNavGroup } from 'bp-framework/dist/common/common.interface';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BrandLogoComponent, ClickTimeoutDirective, GlobalAuthenticationService, IProjectConfigAgnostic, LanguageButtonComponent, LoggerService, LogMethod, PROJECT_CONFIG_TOKEN, ProjectConfig, ThemeToggleComponent } from 'bp-angular-library';

import { PACKAGE_DETAILS } from 'src/environments/package-details';
import { ContentAbstractService } from 'src/app/core/services/env-abstracts';
import { LogLevel } from 'bp-framework/dist/logging/logging.enum';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sidemenu',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, RouterLink, RouterLinkActive, ThemeToggleComponent, BrandLogoComponent, LanguageButtonComponent, ClickTimeoutDirective],
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  public appVersion: any = PACKAGE_DETAILS.version;
  private destroyRef: DestroyRef = inject(DestroyRef);
  public projectConfig = inject<ProjectConfig<IProjectConfigAgnostic>>(PROJECT_CONFIG_TOKEN);

  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  public authService: GlobalAuthenticationService = inject(GlobalAuthenticationService);
  private router: Router = inject(Router);
  private menuCtrl: MenuController = inject(MenuController);
  private loggerService: LoggerService = inject(LoggerService);

  public sideMenuGroups: Partial<ISidemenuNavGroup<any>>[] = [];

  //#region lifecycle hooks
  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.subscribeToAuthEvents();
  }
  //#endregion

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd)
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any): void => this.resolveCurrentSegment(event?.urlAfterRedirects));
  }

  private subscribeToAuthEvents(): void {
    this.authService.isLoggedIn$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((state: boolean) => {
      this.processSidemenuItems();
    });
  }

  @LogMethod(LogLevel.DEBUG, 'SidemenuComponent')
  private processSidemenuItems(): void {
    const tempItems: Partial<ISidemenuNavGroup<DisplayConditionType>>[] = this.contentAbstractService.buildSidemenuRoutes();
    this.sideMenuGroups = tempItems.filter((item: Partial<ISidemenuNavGroup<DisplayConditionType>>) => {
      if (item?.guards?.includes('never')) {
        this.loggerService.log(LogLevel.INFO, 'never');
        return false;
      }

      if (item?.guards?.includes('always')) {
        this.loggerService.log(LogLevel.INFO, 'always');
        return true;
      }

      if (item?.guards?.includes('when-authenticated')) {
        this.loggerService.log(LogLevel.INFO, 'when-authenticated');
        return this.authService.isLoggedIn === true ? true : false;
      }

      if (item?.guards?.includes('when-not-authenticated')) {
        this.loggerService.log(LogLevel.INFO, 'when-not-authenticated');
        return this.authService.isLoggedIn === false ? true : false;
      }

      return true;
    });
    this.loggerService.log(LogLevel.INFO, this.sideMenuGroups);
    //this.loggerService.log(LogLevel.INFO, 'yyyyyyyyyy');
    this.resolveCurrentSegment(this.router.url);
  }

  private resolveCurrentSegment(urlAfterRedirect: string): void {
    let matchingNavItem: Partial<INavGroup> | null = null;

    this.sideMenuGroups?.forEach((item: Partial<INavGroup>) => {
      if (item.path && urlAfterRedirect.includes(item.path)) {
        matchingNavItem = item;
        matchingNavItem.expanded = Array.isArray(item?.children) && item?.children?.length > 0;
      }
    });
  }

  public closeMenu(): void {
    this.menuCtrl.close('primary-sidemenu');
  }

  public async logout(): Promise<void> {
    await this.authService.logout('/login');
  }

  public goToLogin(): void {
    this.router.navigate(['/login']);
  }
}
