import { Component, inject, OnInit } from '@angular/core';
import { OUTLET_ANIMATION } from './shared/animations/animations';
import { SidemenuComponent } from './features/sidemenu/sidemenu.component';
import { IonicModule } from '@ionic/angular';
import { ServiceWorkerService, NetworkStatusBarComponent } from 'bp-angular-library';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [OUTLET_ANIMATION],
  standalone: true,
  imports: [CommonModule, IonicModule, SidemenuComponent, NetworkStatusBarComponent]
})
export class AppComponent implements OnInit {
  private serviceWorkerService: ServiceWorkerService = inject(ServiceWorkerService);

  title = 'bp-back-office';
  sidePaneState!: boolean;

  showNetworkStatus!: boolean;

  ngOnInit(): void {
    this.serviceWorkerService.initialize();
  }

  public handleNetworkStatusChange(isOnline: boolean): void {
    this.showNetworkStatus = isOnline ? false : true;
  }
}
