import { Injectable } from '@angular/core';
import { IApiPayload } from 'bp-framework/dist/api/api.interface';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { UserAbstractService } from 'src/app/core/services/env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class User1x2TeamService extends UserAbstractService {
  loginWithUsernameAndPassword(username: string, password: string): Promise<IApiPayload<Partial<IUserDetails>> | null> {
    throw new Error('Method not implemented.');
  }
  refreshToken(): Promise<Partial<IUserDetails> | null> {
    throw new Error('Method not implemented.');
  }
  getUserProfile(): Promise<IApiPayload<Partial<IUserDetails>> | null> {
    throw new Error('Method not implemented.');
  }

  public async patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null> {
    // TODO: 1x2team does not have language patch functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    // TODO: 1x2team does not have user update functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public watchForLanguageChange(): void {
    // TODO: 1x2team does not have this functionality. Check how to handle this
  }
}
