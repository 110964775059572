import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { AbstractGlobalInterceptor, GlobalAuthenticationService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/services/env-abstracts';

import { from, Observable } from 'rxjs';

@Injectable()
export class GlobalInterceptorBetPlatform extends AbstractGlobalInterceptor {
  private authService: GlobalAuthenticationService = inject(GlobalAuthenticationService);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);

  constructor() {
    super();
  }

  override baseGetAuthToken(): string | null {
    return this.authService.rawToken$.value;
  }

  override baseTryRefreshingToken(): Observable<Partial<IUserDetails> | null> {
    return from(this.userAbstractService.refreshToken());
    // if (this.projectConfig?.features?.storeCredentials !== true) {
    //   // If storing credentials is disabled, just try refresh token
    //   return from(this.userAbstractService.refreshToken());
    // }

    // // If storing credentials is enabled, try logging in with stored credentials first
    // return from(this.storageService.getLocalItem(STORAGE_KEYS.userCredentials)).pipe(
    //   switchMap((credentials: ILoginCredentials | null) => {
    //     if (!credentials?.username || !credentials?.password) {
    //       // If no credentials stored, fall back to refresh token
    //       return from(this.userAbstractService.refreshToken());
    //     }

    //     // If we have credentials, try logging in with them
    //     return from(this.userAbstractService.loginWithUsernameAndPassword(credentials.username, credentials.password)).pipe(
    //       // If login succeeds, return its result
    //       catchError(() => {
    //         // If login fails, fall back to refresh token
    //         return from(this.userAbstractService.refreshToken());
    //       })
    //     );
    //   })
    // );
  }

  override baseHandleAfterFailedTokenRefresh(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === 401 && this.authService.isLoggedIn) {
      this.authService.logout();
    }
  }

  override baseAddTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.authService.rawToken$.value}`)
    });
  }
}
